import React from 'react';
import './spinner.css';

export default function LoadingSpinner({ message }) {
  return (
    <>
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
      <div style={{ fontSize: '18px', fontWeight: 'bolder', textAlign: 'center', marginBottom: '10px' }}>{message}</div>
    </>
  );
}
